<template>
    <b-modal
            :id="modalId"
            size="md"
            :title="modalTitle"
            centered
            scrollable
            title-class="font-22"
            hide-footer
            @shown="setData"
            ref="modal"
    >
        <b-form
                class="purchase-form"
                @submit="handleSubmit"
        >
            <b-form-group
                label-cols-md="3"
                label-align="right"
                label-for="type"
            >
                <template v-slot:label>
                    <strong>Type <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-select
                    v-if="form.status != 2 && form.status != 3"
                    id="type"
                    v-model="form.type"
                    :options="types"
                    value-field="id"
                    text-field="title"
                    required
                    @change="changeTypeHandler"
                >
                    <template #first>
                        <b-form-select-option value="" disabled>-- Please select type --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ types.filter(type => type.id == form.type).map(obj => obj.title)[0] }}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 5"
                    label-cols-md="3"
                    label-align="right"
                    label-for="product"
            >
                <template v-slot:label>
                    <strong>Product</strong>
                </template>
                <b-form-select
                        id="product"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.product_id"
                        :options="products"
                        value-field="id"
                        text-field="title"
                        required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Product --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ products.filter(product => product.id == form.product_id).map(obj =>
                    obj.title)[0] }}
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 1 || form.type === 2 || form.type === 4 || form.type === 5"
                    label-cols-md="3"
                    label-align="right"
                    label-for="file"
            >
                <template v-slot:label>
                    <strong>File</strong>
                </template>
                <b-form-file
                        id="file"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.file"
                        placeholder="Choose a file"
                        drop-placeholder="Drop file here..."
                        accept=".jpg, .png, .pdf, .ppt, .pptx, .doc, .docx"
                ></b-form-file>
                <div class="pt-2">Available formats: .jpg, .png, .pdf, .ppt, .pptx, .doc, .docx</div>
                <div v-if="form.filelink" class="pt-2">
                    <a :href="getFullPathToFile(form.file_id)" target="_blank"><i class="fa fa-download" aria-hidden="true"></i>
                        Download</a>
                </div>
                <div v-if="!form.file && !form.filelink" class="pt-2">File was not attached</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 2 || form.type === 6"
                    label-cols-md="3"
                    label-align="right"
                    label-for="date"
            >
                <template v-slot:label>
                    <strong>Date <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="date"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.date"
                        type="date"
                        required
                />
                <div v-else class="p-2">{{ form.date }}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 3"
                    label-cols-md="3"
                    label-align="right"
                    label-for="book"
            >
                <template v-slot:label>
                    <strong>Book <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-select
                        id="book"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.book_id"
                        :options="booksCategories"
                        value-field="id"
                        text-field="title"
                        required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Book category --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ booksCategories.filter(book => book.id == form.book_id).map(obj =>
                    obj.title)[0] }}
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 4"
                    label-cols-md="3"
                    label-align="right"
                    label-for="app"
            >
                <template v-slot:label>
                    <strong>App <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-select
                        id="app"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.app_id"
                        :options="apps"
                        value-field="id"
                        text-field="title"
                        required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Application --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ apps.filter(app => app.id == form.app_id).map(obj =>
                    obj.title)[0] }}
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 4"
                    label-cols-md="3"
                    label-align="right"
                    label-for="platform"
            >
                <template v-slot:label>
                    <strong>Platform <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-select
                        id="platform"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.platform_id"
                        :options="platforms"
                        value-field="id"
                        text-field="title"
                        required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Platform --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ platforms.filter(platform => platform.id == form.platform_id).map(obj =>
                    obj.title)[0] }}
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 4"
                    label-cols-md="3"
                    label-align="right"
                    label-for="version"
            >
                <template v-slot:label>
                    <strong>Version <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="version"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.version"
                        type="text"
                        required
                />
                <div v-else class="p-2">{{ form.version }}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 8"
                    label-cols-md="3"
                    label-align="right"
                    label-for="employee"
            >
                <template v-slot:label>
                    <strong>Employee <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-select
                        id="employee"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.employee_uid"
                        :options="employees"
                        value-field="uid"
                        text-field="name"
                        required
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Recommended employee --</b-form-select-option>
                    </template>
                </b-form-select>
                <div v-else class="p-2">{{ employees.filter(employee => employee.uid == form.employee_uid).map(obj =>
                    obj.name)[0] }}
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 1 || form.type === 2 || form.type === 3 || form.type === 5 || form.type === 7"
                    label-cols-md="3"
                    label-align="right"
                    label-for="link"
            >
                <template v-slot:label>
                    <strong>Link <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="link"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.link"
                        type="text"
                        placeholder="https://"
                        required
                />
                <div v-else class="p-2">{{form.link}}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 10"
                    label-cols-md="3"
                    label-align="right"
                    label-for="code"
            >
                <template v-slot:label>
                    <strong>Code <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="code"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.code"
                        type="text"
                        required
                />
            </b-form-group>
            <b-form-group
                    v-if="form.status != 2 && form.status != 3 && form.type === 9"
                    label-cols-md="3"
                    label-align="right"
            >
                <strong>Current balance: {{ balance }} Bimi Coins</strong>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 9"
                    :disabled="!balance"
                    label-cols-md="3"
                    label-align="right"
                    label-for="spend_amount"
            >
                <template v-slot:label>
                    <strong>Amount <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="spend_amount"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.spend_amount"
                        type="number"
                        :max="balance"
                        :min="1"
                        @blur="handleBlurNumber($event, 'form.spend_amount')"
                        required
                />
                <div v-else class="p-2">{{form.spend_amount}}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 2"
                    label-cols-md="3"
                    label-align="right"
                    label-for="number_of_visitors"
            >
                <template v-slot:label>
                    <strong>Number of Visitors <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-input
                        id="number_of_visitors"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.number_of_visitors"
                        type="number"
                        min="5"
                        placeholder="minimum 5"
                        required
                />
                <div v-else class="p-2">{{form.number_of_visitors}}</div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 4 || form.type === 5 || form.type === 6 || form.type === 9"
                    label-cols-md="3"
                    label-align="right"
                    label-for="description"
            >
                <template v-slot:label>
                    <strong>Description <span class="text-danger" v-if="form.status != 2 && form.status != 3">*</span></strong>
                </template>
                <b-form-textarea
                        id="description"
                        v-if="form.status != 2 && form.status != 3"
                        v-model="form.description"
                        @input="countWord"
                        required
                />
                <div v-else class="p-2">{{form.description}}</div>
                <div v-if="form.status === 1 && form.type === 5" class="mt-2 text-right font-weight-bold">{{
                    this.descriptionCount }}/100 words
                </div>
            </b-form-group>
            <b-form-group
                    v-if="form.type === 5 && form.status != 2 && form.status != 3"
                    label-cols-md="3"
                    label-align="right"
                    label-for="anonymously"
            >
                <b-form-checkbox
                        id="anonymously"
                        v-model="form.is_send_as_anonymous"
                        value="true"
                >
                    Send Anonymously
                </b-form-checkbox>
            </b-form-group>
            <b-form-group
                    v-if="this.comment"
                    label-cols-md="3"
                    label-align="right"
            >
                <template v-slot:label>
                    <strong>Comment</strong>
                </template>
                <div class="p-2">{{this.comment}}</div>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-end">
                <b-button
                        @click="closeModal"
                        v-if="form.status == 2 || form.status == 3"
                        type="button"
                        variant="primary"
                >
                    Close
                </b-button>
                <ModalButton v-else buttonText="Save" />
            </div>
        </b-form>
    </b-modal>
</template>

<script>
    // import {validateForm} from '@/utils/validation';
    import ModalButton from '@/components/modal-button';

    export default {
        name: 'purchaseModalForm',
        props: {
            'modalId': {
                type: String,
                required: true
            },
            'initialData': Object,
            formMode: String,
            modalTitle: String
        },
        components: {
            ModalButton,
        },
        data() {
            return {
                form: {
                    type: '',
                    file: null,
                    filelink: null,
                    link: null,
                    number_of_visitors: null,
                    date: null,
                    spend_amount: null,
                    description: null,
                    book_id: null,
                    app_id: null,
                    platform_id: null,
                    version: null,
                    product_id: null,
                    is_send_as_anonymous: null,
                    employee_uid: null,
                    code: null,
                    status: null,
                    file_id: null
                },
                descriptionCount: 0,
                comment: null
            }
        },
        computed: {
            buttonText() {
                return this.form.status === 2 || this.form.status === 3 ? 'Close' : 'Save';
            },
            booksCategories() {
                return this.$store.state.library.categories;
            },
            types() {
                let types = this.$store.getters['balanceDetails/types']
                return types.filter((type) => (type.id != 11 && type.id != 12));
            },
            apps() {
                return this.$store.getters['balanceDetails/apps'];
            },
            platforms() {
                return this.$store.getters['balanceDetails/platforms'];
            },
            products() {
                return this.$store.getters['balanceDetails/products'];
            },
            employees() {
                return this.$store.getters['employees/allEmployees'];
            },
            balance() {
                return this.$store.getters['balanceDetails/balance'];
            },
            user() {
                return this.$store.state.user;
            },
        },
        methods: {
            handleSubmit(event) {
                event.preventDefault();
                const data = {
                    type_id: this.form.type,

                    details: JSON.stringify({
                        number_of_visitors: this.form.number_of_visitors,
                        date: this.form.date,
                        description: this.form.description,
                        book_id: this.form.book_id,
                        app_id: this.form.app_id,
                        platform_id: this.form.platform_id,
                        version: this.form.version,
                        product_id: this.form.product_id,
                        is_send_as_anonymous: this.form.is_send_as_anonymous,
                        employee_uid: this.form.employee_uid,
                        spend_amount: this.form.spend_amount,
                        code: this.form.code
                    }),

                    code: this.form.code
                }
                if (this.form.link) {
                    data.link = this.form.link
                }
                if (this.form.file) {
                    data.file = this.form.file
                }
                // if (validateForm(this.form)) {
                this.$emit('onSubmit', data);
                // }
            },
            setData() {
                const details = this.initialData?.transaction_details?.details || {}
                this.form.type = this.initialData?.type_id || '';
                this.form.file = details.file || null;
                this.form.filelink = this.initialData?.transaction_details?.file || null;
                this.form.link = this.initialData?.transaction_details?.link || null;
                this.form.status = this.initialData?.status_id || null;
                this.form.number_of_visitors = details.number_of_visitors || null;
                this.form.date = details.date || null;
                this.form.description = details.description || null;
                this.form.book_id = details.book_id || null;
                this.form.app_id = details.app_id || null;
                this.form.platform_id = details.platform_id || null;
                this.form.version = details.version || null;
                this.form.product_id = details.product_id || null;
                this.form.is_send_as_anonymous = details.is_send_as_anonymous || null;
                this.form.employee_uid = details.employee_uid || null;
                this.form.spend_amount = details.spend_amount || null;
                this.form.code = details.code || null;
                this.comment = details.comment || null;
                this.form.file_id = this.initialData?.transaction_details.id || null
            },
            countWord(event) {
                let count = 0;
                let split = event.split(' ');

                for (var i = 0; i < split.length; i++) {
                    if (split[i] != "") {
                        count += 1;
                    }
                }

                this.descriptionCount = count
            },
            closeModal() {
                this.$bvModal.hide(this.modalId);
            },
            changeTypeHandler() {
                this.form.file = '';
                this.form.filelink = '';
                this.form.link = '';
                this.form.status = '';
                this.form.number_of_visitors = '';
                this.form.date = '';
                this.form.description = '';
                this.form.book_id = '';
                this.form.app_id = '';
                this.form.platform_id = '';
                this.form.version = '';
                this.form.product_id = '';
                this.form.is_send_as_anonymous = '';
                this.form.employee_uid = '';
                this.form.spend_amount = '';
                this.form.code = '';
            },
            getFullPathToFile(id) {
              const url = process.env.VUE_APP_API_PATH + '/bimi-coins/attachment/' + id
              return url
            }
        },
        mounted() {
            this.$store.dispatch('balanceDetails/getTypesRequest')
            this.$store.dispatch('balanceDetails/getAppsRequest')
            this.$store.dispatch('balanceDetails/getPlatformsRequest')
            this.$store.dispatch('balanceDetails/getProductsRequest')
            this.$store.dispatch('employees/getAllEmployees')
            this.$store.dispatch('library/getLibrarySettings')
        },
    }
</script>

<style lang="scss" scoped>
    .survey-form {
        .custom-control-label {
            vertical-align: middle;
        }
    }
</style>
